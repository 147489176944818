.news-item {
    cursor: pointer;
    transition: 0.5s;
}

.news-item:hover {
    /*background-color: white !important;*/
}

.news-item:hover .news-image {
    transform: scale(1.05);
}

.news-image {
    transition: 0.5s;
}
