.showcase-button-dark {
  outline: none !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #f7f7f7 !important;
  flex: 1 1 auto;
  margin: 10px;
  padding: 10px 15px;
  border: 1px solid #f7f7f7 !important;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
  transition: .3s;
  background-color: transparent !important;
  z-index: 1;
  height: initial;
}

.showcase-button-dark:after {
  position: absolute;
  transition: 0.5s;
  content: '';
  width: 0;
  bottom: 0;
  background-color: #f7f7f7 !important;
  height: 120%;
  left: -10%;
  transform: skewX(15deg);
  z-index: -1;
}

.showcase-button-dark:hover {
  cursor: pointer;
  color: black !important;
  visibility: visible !important;
}

.showcase-button-dark:hover:after {
  color: black !important;
  left: -10%;
  width: 120%;
}

.showcase-button-lt {
  outline: none !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex: 1 1 auto;
  margin: 10px;
  padding: 10px 15px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
  transition: .3s;
  background-color: transparent !important;
  z-index: 1;
  height: initial;
  border: 1px solid black !important;
  color: black !important;
}

.showcase-button-lt:after {
  position: absolute;
  transition: 0.5s;
  content: '';
  width: 0;
  bottom: 0;
  height: 120%;
  left: -10%;
  transform: skewX(15deg);
  z-index: -1;
  background-color: black !important;
}
.showcase-button-lt:hover {
  cursor: pointer;
  visibility: visible !important;
  color: #f7f7f7 !important;
}

.showcase-button-lt:hover:after {
  left: -10%;
  width: 120%;
  color: #f7f7f7 !important;
}