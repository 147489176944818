body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: inherit;
  color: inherit;
}

/* override because tab bar wasn't working as documented */

#override-tab-bar-color span[class^='MuiTabs-indicator'], #override-tab-bar-color span[class*=' MuiTabs-indicator']{
  background-color: #009be5 !important;
}

/** ----- Profile ----- **/

.account-profile-image {
  width: 100%;
  max-width: 200px;
  height: auto;
  border-radius: 50%;
}

/** ----- Financial Summary ----- **/

.fsc-main-component {
  display: flex;
  flex-direction: column;
}

.fsc-inner-display-center-helper {
  width: 100%;
  max-width: 420px;
  align-self: center;
}

.fsc-number-row {
  height: 24px;
}

.fsc-number-controller {
  /*overflow-y: scroll;*/
}

.fsc-icon-button {
  transition: all 250ms;
}

.fsc-icon-button:hover {
  cursor: pointer;
}

.fsc-icon-button-rotate {
  transform: rotateX(180deg);
}

/** ----- Confirmation Modals ----- **/

.black-text {
  color: black !important;
}

.thirteen-font {
  font-size: 13pt !important;
}

/** ----- Fixes for the anchor tags on the intro ----- **/
.anchor-fix {
  color: -webkit-link;
  cursor: pointer;
}

.anchor-fix:hover {
  text-decoration: underline;
}
