.background {
    height: 100vh;
    width: 100vw;
    background-image: url("/landing_page_background_image.png");
    background-position: center left;
}

.overlay {
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    display: flex;
    align-items: center;
}

.container {
    padding: 0 40px;
    text-align: center;
}

.title {
    color: white;
}

.welcome-button {
    padding: 0;
    margin: 10px;
    min-width: 300px;
}